import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import { useTranslation } from 'react-i18next';
import Home from '../views/public/home/home';
import Navbar from '../../Navbar/Navbar';
import LoginModal from '../../components/Login/LoginModal';
import Logout from '../../components/Login/Logout';
import { MainSkipLinks } from '../../shared/Elements/SkipLinks/MainSkipLinks';
import { MaterializeCommonStyles } from '../../MaterializeStylesCommons';
import { useTrackingUserSession } from '../../hooks/useTrackingUserSession';

const About = React.lazy(() => import('../views/public/about/about')); // prettier-ignore
const AccountOptions = React.lazy(() => import('../../components/AccountOptions/AccountOptions')); // prettier-ignore
const Activities = React.lazy(() => import('../../components/Activities/Activities')); // prettier-ignore
const ActivitiesMusic = React.lazy(() => import('../../components/Activities/ActivitiesMusic')); // prettier-ignore
const Agenda = React.lazy(() => import('../../components/Agenda/Agenda')); // prettier-ignore
const Artist = React.lazy(() => import('../views/public/artist/artist')); // prettier-ignore
const BusinessRound = React.lazy(() => import('../../components/Rounds/BusinessRound')); // prettier-ignore
const Catalog = React.lazy(() => import('../../components/Catalog/Catalog')); // prettier-ignore
const Contact = React.lazy(() => import('../views/public/contact/contact')); // prettier-ignore
const CultureMap = React.lazy(() => import('../../components/CultureMap')); // prettier-ignore
const CyclePage = React.lazy(() => import('../../components/Cycle/CyclePage')); // prettier-ignore
const DeleteAccountStepper = React.lazy(() => import('../../components/AccountOptions/DeleteAccountStepper')); // prettier-ignore
const Event = React.lazy(() => import('../../components/Event/Event')); // prettier-ignore
const Footer = React.lazy(() => import('../../components/Footer/Footer')); // prettier-ignore
const FrequentQuestions = React.lazy(() => import('../views/public/faq/FrequentQuestions')); // prettier-ignore
const InvitedRound = React.lazy(() => import('../../components/Rounds/InvitedRound')); // prettier-ignore
const Mensajes = React.lazy(() => import('../views/public/mensajes/Mensajes')); // prettier-ignore
const NewPassword = React.lazy(() => import('../../components/NewPassword/NewPassword')); // prettier-ignore
const NewPasswordValidatedCorrect = React.lazy(() => import('../../components/NewPassword/NewPasswordValidatedCorrect')); // prettier-ignore
const NoteDetail = React.lazy(() => import('../../components/Notes/NoteDetail')); // prettier-ignore
const NotesList = React.lazy(() => import('../../components/Notes/NotesList')); // prettier-ignore
const NotFound = React.lazy(() => import('../views/public/notFound/NotFound')); // prettier-ignore
const Notificaciones = React.lazy(() => import('../views/public/notificaciones/Notificaciones')); // prettier-ignore
const ParticipantRound = React.lazy(() => import('../../components/Rounds/ParticipantRound')); // prettier-ignore
const Portfolio = React.lazy(() => import('../../components/Portfolio/Portfolio')); // prettier-ignore
const PortfolioBio = React.lazy(() => import('../../components/PortfolioBio/PortfolioBio')); // prettier-ignore
const PortfolioBioPreview = React.lazy(() => import('../../components/PortfolioBioPreview/PortfolioBioPreview')); // prettier-ignore
const PortfolioValidating = React.lazy(() => import('../../components/PortfolioValidating/PortfolioValidating')); // prettier-ignore
const PrivacyPolicy = React.lazy(() => import('../views/public/privacyPolicy/PrivacyPolicy')); // prettier-ignore
const ProductSingle = React.lazy(() => import('../../components/Products/ProductSingle')); // prettier-ignore
const ProductStepper = React.lazy(() => import('../../components/ProductStepper/ProductStepper')); // prettier-ignore
const ProductValidating = React.lazy(() => import('../../components/ProductValidating/ProductValidating')); // prettier-ignore
const ProgramPointPublic = React.lazy(() => import('../../components/Programs/ProgramPointPublic')); // prettier-ignore
const ProgramPublic = React.lazy(() => import('../../components/Programs/ProgramPublic')); // prettier-ignore
const Register = React.lazy(() => import('../../components/Register/Register')); // prettier-ignore
const RegisterValidated = React.lazy(() => import('../../components/RegisterValidated/RegisterValidated')); // prettier-ignore
const RegisterValidatedCorrect = React.lazy(() => import('../../components/RegisterValidated/RegisterValidatedCorrect')); // prettier-ignore
const RegisterValidatedIncorrect = React.lazy(() => import('../../components/RegisterValidated/RegisterValidatedIncorrect')); // prettier-ignore
const RegisterWaitingValidation = React.lazy(() => import('../../components/RegisterWaitingValidation/RegisterWaitingValidation')); // prettier-ignore
const ResetPassword = React.lazy(() => import('../../components/ResetPassword/ResetPassword')); // prettier-ignore
const ResultsPage = React.lazy(() => import('../../components/ResultsPage/ResultsPage')); // prettier-ignore
const RoundForm = React.lazy(() => import('../../components/Rounds/RoundForm')); // prettier-ignore
const RoundsFAQ = React.lazy(() => import('../views/public/roundsFAQ/roundsFAQ')); // prettier-ignore
const SectionPreview = React.lazy(() => import('../../components/SectionPreview/SectionPreview')); // prettier-ignore
const SectionSingle = React.lazy(() => import('../../components/Section/SectionSingle')); // prettier-ignore
const SectionStepper = React.lazy(() => import('../../components/SectionStepper/SectionStepper')); // prettier-ignore
const SectionValidating = React.lazy(() => import('../../components/SectionValidating/SectionValidating')); // prettier-ignore
const SectorPage = React.lazy(() => import('../../components/Sectors/SectorPage')); // prettier-ignore
const ServiceStepper = React.lazy(() => import('../../components/ServiceStepper/ServiceStepper')); // prettier-ignore
const ServiceValidating = React.lazy(() => import('../../components/ServiceValidating/ServiceValidating')); // prettier-ignore
const SurveyJs = React.lazy(() => import('../../components/Survey/SurveyJs')); // prettier-ignore
const TermsAndConditionsPage = React.lazy(() => import('../../components/TermsAndConditions/TermsAndConditionsPage')); // prettier-ignore
const TurnsTable = React.lazy(() => import('../../components/Turns/TurnsTable')); // prettier-ignore
const YourMICA = React.lazy(() => import('../../components/YourMICA/YourMICA')); // prettier-ignore

export default function PublicLayout(props) {
  const classes = MaterializeCommonStyles();
  const { t } = useTranslation();
  const { location: locationFromProps } = props;
  const shouldHideNavbar = ['/ronda/invitado/'].some((page) =>
    locationFromProps.pathname.startsWith(page)
  );
  const shouldHideFooter = ['/registro', '/ronda/invitado/'].some((page) =>
    locationFromProps.pathname.startsWith(page)
  );
  useTrackingUserSession();

  return (
    <>
      <div id='top-page'></div>
      <MainSkipLinks />
      <div className={classes.rootContentContainer}>
        {!shouldHideNavbar && <Navbar />}
        <main id='main' role='main' tabIndex={-1} aria-label={t('main-label')}>
          <Switch>
            <PublicRoute exact path={'/programa/:friendlyURL'}>
              <ProgramPublic />
            </PublicRoute>
            <PublicRoute exact path={'/programa/:idDomain/:friendlyURL'}>
              <ProgramPointPublic />
            </PublicRoute>
            <PrivateRoute exact path={'/tu-mica'}>
              <YourMICA />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/agenda'}>
              <Agenda />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/agenda/tab/:tabName'}>
              <Agenda />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/agenda/:roundId'}>
              <BusinessRound />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/agenda/turnos/:roundId'}>
              <TurnsTable />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/preguntas-frecuentes'}>
              <RoundsFAQ />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/rondas/perfil/negocio'}>
              <RoundForm />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/portfolio/bio'}>
              <PortfolioBio />
            </PrivateRoute>
            <PublicRoute exact path={'/formulario/:friendlyURL'}>
              <SurveyJs />
            </PublicRoute>
            <PrivateRoute
              exact
              path={'/tu-mica/portfolio/bio/previsualizacion'}
            >
              <PortfolioBioPreview />
            </PrivateRoute>
            <PrivateRoute exact path='/tu-mica/portfolio'>
              <Portfolio />
            </PrivateRoute>
            <PrivateRoute exact path='/tu-mica/portfolio/validacion'>
              <PortfolioValidating />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/portfolio/seccion/:sectionId?'}>
              <SectionStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/seccion-validacion'>
              <SectionValidating />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/tu-mica/portfolio/seccion/:sectionId/previsualizacion'}
            >
              <SectionPreview />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/catalogo'}>
              <Catalog />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/catalogo/producto/:articleId?'}>
              <ProductStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/producto-validacion'>
              <ProductValidating />
            </PrivateRoute>
            <PrivateRoute exact path={'/tu-mica/catalogo/servicio/:articleId?'}>
              <ServiceStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/servicio-validacion'>
              <ServiceValidating />
            </PrivateRoute>

            <PublicRoute path={'/inicio'}>
              <Home />
            </PublicRoute>
            <PublicRoute path={'/terminos-y-condiciones'}>
              <TermsAndConditionsPage />
            </PublicRoute>
            <PublicRoute
              path={'/usuario/:userId/catalogo/articulo/:articleId'}
              exact
            >
              <ProductSingle />
            </PublicRoute>
            <PublicRoute
              path={'/usuario/:userId/portfolio/seccion/:sectionId'}
              exact
            >
              <SectionSingle />
            </PublicRoute>
            <PublicRoute path={'/usuario/:userId/:tabToShow'}>
              <Artist />
            </PublicRoute>
            <PublicRoute path={'/usuario/:userId'}>
              <Artist />
            </PublicRoute>
            <PublicRoute exact path={'/registro/validado/:token'}>
              <RegisterValidated />
            </PublicRoute>
            <PublicRoute exact path={'/registro/validado-correcto'}>
              <RegisterValidatedCorrect />
            </PublicRoute>
            <PublicRoute exact path={'/registro/validado-incorrecto/:email?'}>
              <RegisterValidatedIncorrect />
            </PublicRoute>
            <PublicRoute exact path={'/registro/esperando-validacion/:email?'}>
              <RegisterWaitingValidation />
            </PublicRoute>
            <PublicRoute exact path={'/registro'}>
              <Register />
            </PublicRoute>
            <PublicRoute exact path={'/blanquear-clave/validado-correcto'}>
              <NewPasswordValidatedCorrect />
            </PublicRoute>
            <PublicRoute exact path={'/blanquear-clave/validado/:token'}>
              <NewPassword />
            </PublicRoute>
            <PublicRoute exact path={'/blanquear-clave/:email?'}>
              <ResetPassword />
            </PublicRoute>

            <PublicRoute exact path={'/preguntas-frecuentes'}>
              <FrequentQuestions />
            </PublicRoute>
            <PublicRoute exact path={'/que-es-mica'}>
              <About />
            </PublicRoute>
            <PublicRoute exact path={'/politicas-privacidad'}>
              <PrivacyPolicy />
            </PublicRoute>
            <PublicRoute exact path={'/contacto'}>
              <Contact />
            </PublicRoute>
            <PublicRoute exact path={'/resultados-busqueda/:params?'}>
              <ResultsPage />
            </PublicRoute>

            <PublicRoute exact path={'/notas'}>
              <NotesList />
            </PublicRoute>
            <PublicRoute exact path={'/notas/sector/:sector'}>
              <NotesList />
            </PublicRoute>
            <PublicRoute exact path={'/notas/:friendlyURL'}>
              <NoteDetail />
            </PublicRoute>
            <PublicRoute exact path={'/gps-cultural'}>
              <CultureMap />
            </PublicRoute>
            <PublicRoute exact path={'/actividades'}>
              <Activities isAgenda={true} />
            </PublicRoute>
            <PublicRoute exact path={'/actividades/musica-argentina-22'}>
              <ActivitiesMusic />
            </PublicRoute>
            <PublicRoute exact path={'/actividades/ciclos/:idCycle'}>
              <CyclePage />
            </PublicRoute>
            <PublicRoute exact path={'/actividades/:activityId'}>
              <Event />
            </PublicRoute>
            <PublicRoute exact path={'/actividades/:activityId/reproducir'}>
              <Event />
            </PublicRoute>
            <PublicRoute
              exact
              path={'/actividades/programa/:idDomain?/punto-de-accion/:idNode?'}
            >
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={'/actividades/programa/:idDomain?'}>
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={'/sector/:sector'}>
              <SectorPage />
            </PublicRoute>
            <PublicRoute
              path={'/actividades/:eventType/:activityId/:roundId'}
              exact
            >
              <BusinessRound isPublic={true} />
            </PublicRoute>

            <PrivateRoute exact path={'/mi-cuenta'}>
              <AccountOptions />
            </PrivateRoute>
            <PrivateRoute exact path={'/mi-cuenta/eliminar-cuenta'}>
              <DeleteAccountStepper />
            </PrivateRoute>
            <PrivateRoute exact path={'/mensajes'}>
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute exact path={'/mensajes/conversacion/:conversationId'}>
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/mensajes/conversacion/:conversationId/node'}
            >
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute exact path={'/notificaciones'}>
              <Notificaciones />
            </PrivateRoute>

            {/* pasamanos de BBB de un participante sin usuario en MICA (invitado)  */}
            <PublicRoute exact path={'/ronda/invitado/:token'}>
              <InvitedRound />
            </PublicRoute>

            {/* pasamanos de BBB de un usuario de MICA  */}
            <PrivateRoute exact path={'/ronda/participante/:token'}>
              <ParticipantRound />
            </PrivateRoute>

            <PublicRoute exact path={'/cerrar-sesion'}>
              <Logout />
            </PublicRoute>
            <Route exact path='/'>
              <Redirect to={'/inicio'} />
            </Route>
            <PublicRoute path='**' exact>
              <NotFound />
            </PublicRoute>
          </Switch>
        </main>
      </div>
      <LoginModal />
      {!shouldHideFooter && <Footer />}
    </>
  );
}
